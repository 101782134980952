.product-img-wrapper {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
}

.product-img-wrapper figure.product-img {
    z-index: 1;
    position: absolute;
    border: 0;
    margin: 0;
    padding: 0;
    left: calc(50% + 0px);
    right: auto;
    bottom: 0px;
    top: auto;
    -webkit-transform: translatex(-50%);
    transform: translatex(-50%);
}

figure.product-img.poe-bg {
    width: 100%;
    height: 100%;
    background-image: url(https://cdn.shopifycdn.net/s/files/1/1487/4888/files/C225-8cam_1.jpg?v=1665541859);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
}

figure.product-img.c289-bg {
    width: 100%;
    height: 100%;
    background-image: url(https://cdn.shopifycdn.net/s/files/1/1487/4888/files/C303.jpg?v=1657175604);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
}

figure.product-img.c308-bg {
    width: 100%;
    height: 100%;
    background-image: url(https://cdn.shopifycdn.net/s/files/1/1487/4888/files/C188.jpg?v=1657175604);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
}

figure.product-img.c190-bg {
    width: 100%;
    height: 100%;
    background-image: url(https://cdn.shopifycdn.net/s/files/1/1487/4888/files/C308.jpg?v=1657175604);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
}

.swiper-box,
.product-use {
    position: relative;
    margin-top: 60px;
}

.swiper-box {
    padding-top: 50px;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 80px;
}

.product-use .swiper-container {
    max-width: 900px;
    width: 100%;
}

.product-use .swiper-container img {
    width: 100%;
}

.product-use .swiper-container .swiper-pagination {
    position: relative;
    width: 100%;
}

.product-use .swiper-container .swiper-pagination-bullet {
    width: 25%;
    height: auto;
    background: none;
}

.product-use .swiper-container .swiper-pagination-bullet img {
    width: 100%;
}

.swiper-pagination.product-use-pagination {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.swiper-pagination.product-use-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    color: #fff;
    background: #2E90FF;
}

.swiper-pagination.product-use-pagination span.swiper-pagination-bullet {
    padding: 10px 18px;
    height: 44px;
    border-radius: 5px;
    width: auto;
    background: none;
    opacity: 1;
    color: #666;
    font-size: 22px;
/*     font-family: Montserrat-Regular, Montserrat; */
    line-height: 22px;
}

.product-use h2,
.product-use p {
    text-align: center;
}

.choose-product {
    position: absolute;
    top: 25%;
    right: 12%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    cursor: pointer;
}

.choose-cams {
    width: 60px;
    height: 60px;
    padding: 10px;
    margin: auto;
    margin-left: 20px;
    background: rgb(46 144 255 / 50%);
    border-radius: 50%;
}

.choose-cams img {
    max-width: 50px;
}

.choose-card {
    max-width: 600px;
    display: flex;
    opacity: 0;
    justify-content: space-between;
    /* border: 2px solid #2E90FF; */
    /* border-radius: 15px; */
    background: #fff;
}

.get-it {
    min-width: 150px;
    min-height: 111px;
    margin: auto;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    text-align: center;
    background-color: #2E90FF;
}

.card-detail {
    padding: 10px 70px 10px 20px;
    border: 2px solid #2E90FF;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.card-detail h3 {
/*     font-family: Montserrat-Regular, Montserrat; */
    font-weight: 600;
    font-size: 25px;
    line-height: 1.5;
    margin-bottom: 5px;
}

.card-detail p {
    text-align: left;
}

.choose-card .card-detail p span {
    line-height: 1.5;
    font-weight: 500;
    font-size: 14px
}

.get-it a.learn-line {
    font-size: 22px;
    line-height: 111px;
    color: #fff;
}

.choose_active .choose-card {
    opacity: 1;
}

.choose_active .choose-cams {
    background: #2E90FF;
}

.choose-product.choose1 {
    top: 37%;
}

.choose-product.choose2 {
    top: 49%;
}

.choose-product.choose3 {
    top: 40%;
}

.choose-product.choose6 {
    flex-direction: row;
    top: 17%;
    left: 18%;
    right: 3%;
}

ul.choose-product-mobile {
    display: none;
}

.App {
    background-color: #F5F7FA;
}

.smart-app.app-row {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 60px;
    padding: 96px 0;
    display: flex;
/*     flex-wrap: wrap; */
    flex-direction: row-reverse;
    justify-content: space-between;
    background-color: #F5F7FA;
}

.main-content .container .app-content h3 {
/*     font-family: Montserrat-Medium, Montserrat; */
    font-size: 30px;
    margin-bottom: 20px;
}

.main-content .container .app-content p span {
    line-height: 2;
}

.app-content a.learn-line {
    margin: 30px 0 0 0;
    display: block;
}

.app-content {
    max-width: 560px;
    margin: auto 0;
}

.app-img {
    padding: 0 180px;
}

.app-software {
    margin-top: 40px;
}

.app-phone,
.app-client {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    width: 100%;
    margin-top: 20px;
}

.app-phone img {
    max-width: 200px;
    margin-right: 20px;
}

.app-client img {
    max-width: 220px;
    margin-right: 20px;
}

.privacy-content {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.privacy-text {
    max-width: 520px;
    margin: auto 0;
    padding: 100px 0;
    background: url(https://cdn.shopify.com/s/files/1/1487/4888/files/privacy-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 500px 390px;
}

.privacy-img {
    max-width: 580px;
}

.privacy-img img {
    width: 100%;
    border-radius: 8px;
}

.privacy-text p {
    margin-top: 40px;
}

.main-content .container .privacy-content p span {
    line-height: 25px;
}

.main-content .container .privacy-content a.learn-line {
    margin: 20px 0 40px 0;
    display: block;
}

.video-clips {
    margin-top: 80px;
    padding: 112px 0;
    background-color: #F5F7FA;
}

.clips-content {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 60px;
}

.video-clips-img {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.main-content .container .clips-content p span {
    line-height: 25px;
}

.main-content .container .clips-content p:last-child {
    margin-bottom: 20px;
}

.main-content .container .clips-content a.learn-line {
    display: inline-block;
    margin-top: 20px;
}

.video-clips .swiper-slide {
    width: 400px;
    transition-timing-function: linear;
}

@media only screen and (max-width:1200px) {
    .video-clips .swiper-slide {
        width: 400px;
    }
}

@media only screen and (max-width:980px) {
    .video-clips .swiper-slide {
        width: 400px;
    }
}

@media only screen and (max-height:480px) {
    .video-clips .swiper-slide {
        width: 400px;
    }
}

.video-clips .swiper-slide img {
    width: 100%;
    border-radius: 10px;
}

.video-clips .swiper-slide .title {
    position: absolute;
    transform: rotate(90deg);
    transform-origin: left top;
    left: -3px;
    font-size: 11px;
    color: rgb(102, 102, 102);
  border-radius:10px;
}

.video-clips .swiper-slide {
    opacity: .4;
}

.video-clips .swiper-slide.swiper-slide-prev,
.video-clips .swiper-slide.swiper-slide-active,
.video-clips .swiper-slide.swiper-slide-next {
    opacity: 1;
}

.video-clips .swiper-button-prev,
.video-clips .swiper-button-next {
    color: #fff;
    height: 96px;
    width: 48px;
    background-color: rgba(0, 0, 0, .1);
    background-color: rgba(0, 0, 0, .2);
    top: 45%;
}

.video-clips .swiper-button-prev {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    left: 0px;
}

.video-clips .swiper-button-next {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    right: 0px;
}

.item_top ul li {
    position: relative;
}

.item_top ul li.nav_active a i {
    display: block;
    position: absolute;
    border-radius: 2px;
    width: 100%;
    height: 2px;
    background: #222;
    left: 0;
    bottom: 2px;
}

.item_bottom ul div {
    display: flex;
    justify-content: space-between;
}

body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure {
    border: 0;
    margin: 0;
    outline: 0;
    padding: 0;
}

a {
    text-decoration: none;
}


/* @font-face {
font-family: "Montserrat-Regular";
src: url("https://www.zositech.com/wp-content/themes/zosi/fonts/Montserrat-Regular.ttf");
src: url("../zosi-newindex/Montserrat/Montserrat-Regular.ttf");
}

@font-face {
font-family: "Montserrat-ExtraBold";
src: url("https://www.zositech.com/wp-content/themes/zosi/fonts/Montserrat-ExtraBold.ttf");
src: url("../zosi-newindex/Montserrat/Montserrat-ExtraBold.ttf");
}

@font-face {
font-family: "Montserrat-Medium";
src: url("https://www.zositech.com/wp-content/themes/zosi/fonts/Montserrat-Medium.ttf");
src: url("../zosi-newindex/Montserrat/Montserrat-Medium.ttf");
}

@font-face {
font-family: "Montserrat-Bold";
src: url("https://www.zositech.com/wp-content/themes/zosi/fonts/Montserrat-Bold.ttf");
src: url("../zosi-newindex/Montserrat/Montserrat-Bold.ttf");
}

@font-face {
font-family: "Montserrat-Black";
src: url("https://www.zositech.com/wp-content/themes/zosi/fonts/Montserrat-Bold.ttf");
src: url("../zosi-newindex/Montserrat/Montserrat-Black.ttf");
} */

@font-face {
  font-family: 'iconfont';  /* Project id 3305848 */
  src: 
       url('https://at.alicdn.com/t/font_3305848_mqev4nbid7a.woff2?t=1650864522104') format('woff2'),
       url('https://at.alicdn.com/t/font_3305848_mqev4nbid7a.woff?t=1650864522104') format('woff'),
       url('https://at.alicdn.com/t/font_3305848_mqev4nbid7a.ttf?t=1650864522104') format('truetype');
}
.iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.container p span {
/*     font-family: Montserrat-Medium, Montserrat; */
    font-weight: 500;
    font-size: 17px;
    line-height: 2.5;
/*     color: #222222; */
}

.main-content .container h2 {
/*     font-family: Montserrat-Medium, Montserrat; */
    font-weight: 600;
    font-size: 45px;
    line-height: 1.5;
}

.iconfont {
    font-family: "iconfont" !important;
    font-size: 24px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
}

.main-content .container {
    margin-top: 0px;
    z-index: 1;
    max-width: 100%!important;
}

.home-banner h1 {
/*     font-family: Montserrat-Blod, Montserrat; */
    font-weight: 600;
    font-size: 94px;
    color: #222222;
    margin-bottom: 30px;
    max-width: 500px;
    line-height: 1.2;
    box-shadow: #bebebe;
}

@keyframes typing {
    from {
        width: 0;
    }
}

@keyframes cursor-blink {
    50% {
        border-color: transparent;
    }
}

.home-banner p {
    max-width: 600px;
}

.home-banner p span {
    font-size: 24px;
    color: #222222;
    font-weight: 400;
/*     font-family: Montserrat-Medium, Montserrat!important; */
    margin-bottom: 50px;
    max-width: 680px;
    line-height: 1.5;
}

.banner-text {
    position: absolute;
    max-width: 1200px;
    width: 100%;
    text-align: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.slide2 .banner-text {
    z-index: 2;
}

a.learn-button {
    display: block;
    width: 150px;
    text-align: center;
    background: linear-gradient( 150deg, #2E90FF, #2E90FF);
/*     font-family: 'Montserrat-Regular'; */
    line-height: 48px;
    border-radius: 10px;
    color: #fff;
    margin-top: 30px;
    transition: top .5s;
    -moz-transition: top .5s;
    -webkit-transition: top .5s;
    -o-transition: top .5s;
    -ms-transition: top .5s;
}

a.learn-button i.iconfont,
.product-banner-text a i.iconfont,
.learn-line i.iconfont {
/*     transform: rotate( 270deg);
    -ms-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -webkit-transform: rotate( 270deg);
    -o-transform: rotate(270deg); */
    display: inline-block;
    font-size: 14px;
    margin-left: 10px;
}
.app-content a.learn-line:hover,.privacy-content a.learn-line:hover,.clips-content a.learn-line:hover {
    color: #2e90ff;
}
.learn-button:hover {
    position: relative;
    top: -6px;
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    -webkit-box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    -o-box-shadow: 0 5px 10px #00000033;
    -ms-box-shadow: 4 5px 10px rgba(0, 0, 0, .2);
}

.home-banner .swiper-container {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

.home-banner .swiper-container .swiper-slide {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: 46vw;
}
.home-banner .slide4 .banner-content {
        background: url("https://cdn.shopify.com/s/files/1/1487/4888/files/PC_-2.jpg?v=1680082997");
}
.home-banner .slide5 .banner-content {
        background: url("https://cdn.shopifycdn.net/s/files/1/1487/4888/files/C289-_-BANNER_1.jpg?v=1672969523");
}
.home-banner .slide6 .banner-content {
        background: url("https://cdn.shopify.com/s/files/1/1487/4888/files/c296_d4f7b974-fcfb-42da-ba8b-bfdd34f34d48.jpg?v=1673578800");
}
.home-banner .slide7 .banner-content {
        background: url("https://cdn.shopify.com/s/files/1/1487/4888/files/c296_d4f7b974-fcfb-42da-ba8b-bfdd34f34d48.jpg?v=1673578800");
}
.home-banner .slide1 .banner-content {
        background: url("https://cdn.shopifycdn.net/s/files/1/1487/4888/files/C182-8CAM-banner-PC.jpg?v=1680241564");
}

.home-banner .slide2 .banner-content {

  background: url("https://cdn.shopifycdn.net/s/files/1/1487/4888/files/w4-pro-banner-PC.jpg?v=1680241572");
}

.home-banner .slide3 .banner-content {
    background: url("https://cdn.shopifycdn.net/s/files/1/1487/4888/files/C289-2-PC_2.jpg?v=1680244612");
}
.home-banner .slide3 h1,.home-banner .slide3 span{
  color:#fff;
}
.home-banner .swiper-container .swiper-slide .banner-content {
    box-sizing: border-box;
    position: relative;
    height: 100%;
    width: 100%;
    text-align: center;
    color: #fff;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
}

.home-banner .swiper-button-next,
.home-banner .swiper-button-prev {
    color: #fff;
    height: 96px;
    width: 48px;
    opacity: 0;
    background-color: rgba(0, 0, 0, .1);
}
.slide4 .banner-text {
    text-align: left;
      top: 50%;
}
.home-banner .slide4 .banner-text a.learn-button {
/*     margin: 0 auto; */
    background: #ff9100;
    width: 250px;
    border-radius: 24px;
    font-weight: 600;
}
.home-banner .slide4 .banner-text p {
    max-width: 600px;
/*     margin: 0 auto; */
    
}
.home-banner .slide4 .banner-text p span{
  color:#fff;
      background-color: #50433b;
    border-radius: 5px;
    padding: 0px 20px;
}
.home-banner .slide4 .banner-text h1 {
    font-weight: 600;
    margin: 0 auto;
    max-width: 1200px;
    color:#50433b;
      margin-bottom: 20px;
/*       text-transform: uppercase; */
}
.home-banner .slide7 .banner-text h1{
  font-size:68px;
}
.home-banner .swiper-container:hover .swiper-button-next,
.home-banner .swiper-container:hover .swiper-button-prev {
    opacity: 1;
}

.home-banner .swiper-button-next:hover,
.home-banner .swiper-button-prev:hover {
    background-color: rgba(0, 0, 0, .1);
}

.home-banner .swiper-button-next {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    right: 0px;
}

.home-banner .swiper-button-prev {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    left: 0px;
}

.home-banner .swiper-button-next:after,
.home-banner .swiper-button-prev:after {
    font-size: 24px;
    font-weight: 500;
}

.home-banner .swiper-container-horizontal>.swiper-pagination-bullets,
.home-banner .swiper-pagination-custom,
.home-banner .swiper-pagination-fraction {
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.home-banner .swiper-pagination-clickable .swiper-pagination-bullet {
    width: 70px;
    height: 4px;
    border-radius: 10px;
    margin: 0 10px;
    background: rgb(255 255 255 / 50%);
    opacity: 0.6;
}

.home-banner span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #fff;
    opacity: 1;
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    -webkit-box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
    -o-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    -ms-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}

.banner-video {
    width: 100%;
    height: 100%;
    position: absolute;
}

video.vc-video.lazyloadVideo {
    width: 100%;
    height: 100%;
    z-index: 0;
}

.product-banner {
    margin-bottom: 16px;
}

.product-banner ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.product-banner ul li {
    position: relative;
/*     width: calc(50vw - 16px);
    height: 700px; */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #FBFBFD;
}

.product-banner-text {
    text-align: center;
    margin-top: 50px;
    z-index: 400;
    position: relative;
}

.product-banner-text>p {
    margin-bottom: 30px;
}

.product-banner-text h2,
.product-video .swiper-container .swiper-slide .banner-text h2,
.client_text h2,
.product-use h2,
.app-content h2 {
/*     font-family: Montserrat-Medium, Montserrat; */
    font-weight: 600;
    font-size: 45px;
    line-height: 1.5;
}

.product-banner-text p span,
.product-video .swiper-container .swiper-slide .banner-text p span {
/*     font-family: Montserrat-Medium, Montserrat; */
    font-weight: 500;
    font-size: 17px;
    line-height: 2.5;
}

.product-banner a.product-link {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    height: 100%;
    width: 100%;
    z-index: 300;
    cursor: pointer;
}

.product-banner-text a,
.learn-line {
/*     font-family: Montserrat-Regular, Montserrat; */
    font-weight: 400;
    font-size: 17px;
    color: #2E90FF;
    margin: 0 10px;
}

.learn-line.buy {
    background-color: #2E90FF;
    color: #fff;
    width: 130px;
    text-align: center;
    height: 30px;
    border-radius: 20px;
    line-height: 28px;
    display: inline-block;
}

.product-banner-text a:hover,
.learn-line:hover {
    text-decoration: underline;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
}

.under-line {
    width: 100%;
    border-bottom: 1px solid #666666;
    margin-bottom: 10px;
}

.main-content .container .version p span {
    font-size: 13px;
    color: #666;
}

@keyframes under_liner {
    0% {
        left: 100%;
        width: 0;
        right: 0;
        height: 0.5px;
    }
    100% {
        left: 0;
        width: 100%;
        right: 0;
        height: 0.5px;
    }
}
 .video-clips .swiper-slide .title i.iconfont {
                width: 50px;
                height: 50px;
                position: absolute;
                left: 50%;
                top: 40%;
                font-size: 46px;
                -webkit-transform: translatex(-50%);
                transform: translatex(-50%);
                cursor: pointer;
                background: url("https://cdn.shopifycdn.net/s/files/1/1487/4888/files/play_video.png?v=1651125870");
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100%;
            }
            
            .video-clips .swiper-slide .title {
                position: absolute;
                transform: inherit;
                transform-origin: left top;
                font-size: 11px;
                width: 100%;
                height: 99%;
                background: rgb(0 0 0 / 30%);
                left: 0;
            }
            
            #youtube-videos {
                display: none;
            }
            
            #youtube-videos {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, .6);
                z-index: 9999;
            }
            
            .video-content {
                position: absolute;
                text-align: center;
                width: 100%;
                top: 50%;
                transform: translateY(-50%);
                overflow: visible;
            }
            
            #youtube-videos iframe {
                width: 1080px;
                height: 608px;
            }
            
            .video-list {
                display: inline-block;
                position: relative;
                overflow: visible;
            }
            
            .close-video {
                position: absolute;
                right: -40px;
                top: 0px;
                cursor: pointer;
            }
            
            .close-video i.iconfont {
                font-size: 36px;
                font-weight: 600;
            }
@media (max-width:970px) {
    ul.nav {
        display: none;
    }
    .main-content .container p span {
        font-size: 14px;
    }
    .main-content .container h2,
    .product-banner-text h2,
    .product-video .swiper-container .swiper-slide .banner-text h2,
    .client_text h2,
    .product-use h2,
    .app-content h2 {
        font-size: 24px;
        line-height: 1;
    }
    .home-banner .swiper-container .swiper-slide {
        height: 480px;
    }
    .home-banner .slide1 .banner-content {
      background: url("https://cdn.shopifycdn.net/s/files/1/1487/4888/files/C182-8CAM-banner.jpg?v=1680241564");
        
    }
    .home-banner .slide2 .banner-content {
        background: url("https://cdn.shopifycdn.net/s/files/1/1487/4888/files/w4-pro-banner.jpg?v=1680241573");
    }
    .home-banner .slide3 .banner-content {
        background: url("https://cdn.shopifycdn.net/s/files/1/1487/4888/files/C289-2-_2.jpg?v=1680244612");
    }
      .home-banner .slide4 .banner-content {
        background: url("https://cdn.shopify.com/s/files/1/1487/4888/files/2_025462c6-b4e8-4bd6-99e9-5f5a8b1c1089.jpg?v=1680082997");
            background-position-y: 0px!important;
    }
  .home-banner .slide5 .banner-content {
        background: url("https://cdn.shopifycdn.net/s/files/1/1487/4888/files/C289-_-BANNER-_750_1.jpg?v=1672969523");
}
    .home-banner .slide6 .banner-content {
        background: url("https://cdn.shopify.com/s/files/1/1487/4888/files/C296.jpg?v=1673578800");
}
      .home-banner .slide7 .banner-content {
        background: url("https://cdn.shopify.com/s/files/1/1487/4888/files/C296.jpg?v=1673578800");
}
    .banner-text {
        text-align: center;
        top: 25%;
    }
    .home-banner h1 {
        font-size: 44px;
        margin-bottom: 0;
        max-width: 100%;
    }
  .home-banner .slide7 .banner-text h1{
  font-size:38px;
    color:#000!important;
}
  .home-banner .slide7 .banner-text p span{
    fofont-size:18px;
    color:#666666!important;
  }
    .home-banner p {
        max-width: 100%;
    }
    .main-content .container .home-banner .banner-text>p:first-child span {
        font-size: 14px;
    }
    a.learn-button {
        margin: 0 auto;
        margin-top: 20px;
        width: 136px;
        border-radius: 6px;
        line-height: 39px;
    }
    .main-content .container .home-banner p span {
        font-size: 18px;
    }
    .product-banner {
        margin-bottom: 10px;
    }
    .product-banner-text p span,
    .product-video .swiper-container .swiper-slide .banner-text p span {
        font-size: 14px;
        line-height: 2.5;
    }
    .product-banner-text a,
    .learn-line {
        font-size: 16px;
    }
    .product-banner ul {
        display: block;
    }
    .product-banner ul li {
        width: 100%;
        height: 370px;
    }
    .product-banner-text>p {
        margin-bottom: 5px;
    }
    .product-banner-text {
        margin-top: 10px;
        padding-top: 25px;
    }
    figure.product-img.poe-bg,
    figure.product-img.c289-bg {
        width: 100%;
        height: 100%;
    }
    figure.product-img.c308-bg,
    figure.product-img.c190-bg {
        width: 100%;
        height: 100%;
    }
  .product-use .swiper-container {
    padding-bottom: 185px;
}
    .swiper-pagination.product-use-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
        flex-flow: nowrap;
    }
    .swiper-pagination.product-use-pagination span.swiper-pagination-bullet {
        font-size: 18px;
        padding: 5px;
        margin: 0 10px;
    }
    .swiper-box,
    .product-use {
        margin-top: 30px;
    }
    .get-it a.learn-line {
        font-size: 16px;
        display: block;
        line-height: initial;
    }
    .get-it {
        min-width: 30%;
        min-height: 92px;
        border-radius: inherit;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        align-items: center;
        display: -webkit-flex;
        background: #2E90FF;
    }
    .choose-cams h3 {
        font-size: 18px;
        position: absolute;
        top: -25px;
        left: 12px;
        right: 0;
        color: #2E90FF;
    }
    .card-detail {
        padding: 10px 15px;
        border-radius: initial;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        align-items: center;
        display: -webkit-flex;
        min-height: 65px;
        border-right: none;
        background-color: #fff;
    }
    .choose_active .choose-card {
        height: 65px;
    }
    .choose-product {
        flex-direction: column-reverse;
        bottom: 0;
        display: none;
    }
    ul.choose-product-mobile {
        list-style: none;
        padding: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        position: absolute;
        bottom: -200px;
    }
    ul.choose-product-mobile li {
        width: 30%;
    }
    ul.choose-product-mobile .choose-cams {
        margin: auto;
        position: relative;
    }
    ul.choose-product-mobile .choose-card {
        position: absolute;
        top: -145px;
        flex-direction: row-reverse;
        opacity: 1;
        left: 0;
        height: 65px;
        margin: 0 10px;
    }
    .choose-product-mobile li.mobile-active .choose-card {
        display: flex;
        min-height: 65px;
        height: auto;
        background-color: #2E90FF;
        border-radius: 15px;
    }
    .mobile-active .choose-cams {
        background: #2E90FF;
    }
    .choose-product-mobile li h3 {
        display: none;
    }
    .choose-product-mobile li.mobile-active h3 {
        display: block;
        width:75px;
    }
    .choose-product-mobile li .choose-card {
        display: none;
    }
    .smart-app.app-row {
        padding: 30px 0;
        height: 650px;
      	flex-wrap: wrap;
        overflow: hidden;
    }
    .app-content {
        text-align: center;
        max-width: 100%;
    }
  .app-img img {
    max-width: 200px;
}
    .main-content .container .app-content h3 {
        font-size: 18px;
        font-weight: 400;
        line-height: 40px;
        margin: 0;
    }
    .app-content a.learn-line {
        margin-top: 15px;
    }
    .app-software {
        margin-top: 20px;
    }
    .app-phone img {
        width: 120px;
        margin-right: 10px;
    }
    .app-client img {
        width: 150px;
        margin-right: 10px;
    }
    .app-phone,
    .app-client {
        margin-top: 10px;
        justify-content: center;
    }
    .app-img {
        padding: 0;
        margin: 0 auto;
        margin-top: 20px;
    }
    .privacy-content {
        margin-top: 30px;
    }
    .privacy-text {
        width: 100%;
        text-align: center;
        padding: 50px 20px;
        margin-bottom: 20px;
        max-width: 100%;
        background-size: contain;
    }
    .privacy-text p {
        margin-top: 20px;
    }
    .privacy-img {
        width: 100%;
        padding: 0 20px;
        max-width: 100%;
    }
    .clips-content {
        margin-bottom: 20px;
    }
    .video-clips {
        padding: 40px 0;
        margin-top: 30px;
    }
    .video-clips .swiper-slide {
        width: 192px;
    }
    .video-clips .swiper-button-prev,
    .video-clips .swiper-button-next {
        width: 24px;
        height: 48px;
    }
    .video-clips .swiper-button-next:after,
    .video-clips .swiper-button-prev:after {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
    }
/*     .footer-sidebar ul {
        padding: 0;
        width: 48%;
    } */
    footer .container {
        width: auto;
      /*         max-width: 1032px;
        margin: 0 auto;
        padding: 0 18px; */
        padding: 0px 20px;
    }
    body {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
    }
    html {
        overflow: hidden;
    }
    .choose-product-mobile li:first-child+li .choose-card::before,
    ul.choose-product-mobile li:first-child+li .choose-card::after {
        left: 47%;
    }
    ul.choose-product-mobile li:first-child+li .choose-card::after {
        left: 46.5%;
    }
    .choose-product-mobile li:first-child+li+li .choose-card::before,
    ul.choose-product-mobile li:first-child+li+li .choose-card::after {
        left: 84%;
    }
    ul.choose-product-mobile li:first-child+li+li .choose-card::after {
        left: 83.5%;
        border-top: 12px solid #2E90FF;
    }
    .choose-product-mobile li.mobile-active .choose-card::before,
    .choose-product-mobile li.mobile-active .choose-card::after {
        position: absolute;
        bottom: -20px;
        left: 26px;
        content: '';
        width: 0;
        height: 0;
        border-right: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid transparent;
        border-top: 10px solid #2E90FF;
    }
    .choose-product-mobile li.mobile-active .choose-card::after {
        left: 24px;
        border-right: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-left: 12px solid transparent;
        border-top: 12px solid #ffffff;
    }
  #youtube-videos .video-content iframe {
                    width: 100%;
                    max-width: 750px;
                    height: 75vw;
                    max-height: 560px;
                }
                .video-list {
                    width: 100%;
                }
                .close-video {
                    position: absolute;
                    right: 0px;
                    top: -40px;
                    cursor: pointer;
                }
}
//product content
.product-collection ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    
    .product-banner {
        margin-bottom: 16px;
      margin-top:50px;
    }
          .product-banner.product-active {
    margin-bottom: 50px;
}
    @media screen and (min-width: 1024px) {
        .product-banner ul {
            list-style: none;
            max-width: 1200px;
            margin: 0 auto;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            /* justify-content: space-between; */
        }
        .product-banner ul li:first-child {
            margin-right: 25px;
              flex: 1.77;
        }
        .product-banner ul li {
            position: relative;
            flex: 1;
            border-radius: 20px;
               padding-bottom: calc(100% * 190 / 710);
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-color: #FBFBFD;
        }
    }
    
    .product-banner ul {
        list-style: none;
        max-width: 1200px;
        margin: 0 auto;
        margin-bottom: 50px;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        /* justify-content: space-between; */
    }
    
    .product-banner ul li:first-child {
        margin-right: 25px;
    }
    
    .product-banner ul li {
        position: relative;
        flex: 1;
           padding-bottom: calc(100% * 190 / 710);
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #FBFBFD;
    }
    
    .product-banner-text {
        text-align: center;
        margin-top: 60px;
        z-index: 400;
        position: relative;
    }
    
    .product-banner-text>p {
        margin-bottom: 30px;
    }
    
    .product-banner-text h2,
    .product-video .swiper-container .swiper-slide .banner-text h2,
    .client_text h2,
    .product-use h2,
    .app-content h2 {
        /*     font-family: Montserrat-Medium, Montserrat; */
        font-weight: 600;
        font-size: 45px;
        line-height: 1.5;
    }
    
    .product-banner-text p span,
    .product-video .swiper-container .swiper-slide .banner-text p span {
        /*     font-family: Montserrat-Medium, Montserrat; */
        font-weight: 500;
        font-size: 17px;
        line-height: 2.5;
    }
    
    .product-banner a.product-link {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        height: 100%;
        width: 100%;
        z-index: 300;
        cursor: pointer;
    }
    
    .product-banner-text a,
    .learn-line {
        /*     font-family: Montserrat-Regular, Montserrat; */
        font-weight: 400;
        font-size: 17px;
        color: #2E90FF;
        margin: 0 10px;
    }
    
    .learn-line.buy {
        background-color: #2E90FF;
        color: #fff;
        width: 130px;
        text-align: center;
        height: 30px;
        border-radius: 20px;
        line-height: 28px;
        display: inline-block;
    }
    
    .product-banner-text a:hover,
    .learn-line:hover {
        text-decoration: underline;
    }
    
    .product-img-wrapper {
        position: absolute;
        bottom: 0;
        height: 100%;
        width: 100%;
    }
    
    .product-img-wrapper figure.product-img {
        z-index: 1;
        position: absolute;
/*         border-radius: 20px; */
        border: 0;
        margin: 0;
        padding: 0;
        left: calc(50% + 0px);
        right: auto;
        bottom: 0px;
        top: auto;
        -webkit-transform: translatex(-50%);
        transform: translatex(-50%);
    }
    .product-banner.product-active ul {
    flex-direction: row-reverse;
}
.product-banner.product-active ul li:first-child {
    margin: 0;
    margin-left: 25px;
}
    figure.product-img.poe-bg {
        width: 100%;
        height: 100%;
        background-image: url(https://cdn.shopifycdn.net/s/files/1/1487/4888/files/C225_3279de67-fad2-473f-81b8-c134dff2b822.jpg?v=1683617211);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
    }
    
    figure.product-img.c289-bg {
        width: 100%;
        height: 100%;
        background-image: url(https://cdn.shopifycdn.net/s/files/1/1487/4888/files/c296_f9227fc0-ef37-40de-b633-ef68ef76de74.jpg?v=1683617211);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
    }
    
    figure.product-img.c308-bg {
        width: 100%;
        height: 100%;
        background-image: url(https://cdn.shopifycdn.net/s/files/1/1487/4888/files/c182_f6aad449-0fc4-431a-a4b5-0142c02bbf72.jpg?v=1683617210);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
    }
    
    figure.product-img.c190-bg {
        width: 100%;
        height: 100%;
        background-image: url(https://cdn.shopifycdn.net/s/files/1/1487/4888/files/c188_45b08f71-ccf8-49a4-b2b9-cb717f9a14d5.jpg?v=1683617210);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
    }
    
    @media screen and (max-width: 750px) {
        .product-banner {
            margin-bottom: 10px;
          margin-top:40px;
        }
      .product-banner.product-active {
    margin-bottom: 40px;
}
        .product-banner-text p span,
        .product-video .swiper-container .swiper-slide .banner-text p span {
            font-size: 14px;
            line-height: 2.5;
        }
        .product-banner-text a,
        .learn-line {
            font-size: 16px;
        }
        .product-banner ul {
            display: block;
            margin: 0;
          padding:0 10px
        }
        .product-banner ul li {
            /* width: 100%; */
            height: 520px;
            padding-bottom: 30px;
            display: flex;
            flex-direction: column-reverse;
            background: #f5f6fa;
/*             margin: 0 10px; */
            margin-bottom: 20px;
            border-radius: 20px;
        }
        .product-banner ul li:first-child {
            margin-right: 10px;
        }
        .product-banner-text>p {
            margin-bottom: 5px;
        }
        .product-banner-text {
            margin-top: 10px;
            padding-top: 25px;
        }
        figure.product-img.poe-bg,
        figure.product-img.c289-bg {
            width: 100%;
            height: 100%;
        }
        figure.product-img.c308-bg,
        figure.product-img.c190-bg {
            width: 100%;
            height: 100%;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }
        .product-img-wrapper figure.product-img {
            border-radius: 0;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }
        .product-img-wrapper {
            position: absolute;
            top: 0;
            height: 375px;
            width: 100%;
        }
        figure.product-img.poe-bg {
            width: 100%;
            height: 100%;
            background-image: url(https://cdn.shopifycdn.net/s/files/1/1487/4888/files/C225-4cam_15eeb2ca-92a3-4e19-8994-1cce8cbbb4cf.png?v=1683617211);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
        }
        figure.product-img.c289-bg {
            width: 100%;
            height: 100%;
            background-image: url(https://cdn.shopifycdn.net/s/files/1/1487/4888/files/c296.png?v=1683617211);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
        }
        figure.product-img.c308-bg {
            width: 100%;
            height: 100%;
            background-image: url(https://cdn.shopifycdn.net/s/files/1/1487/4888/files/C182_79db767f-ab5f-45b6-a7a0-5c9a96d871e3.png?v=1683617211);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
        }
        figure.product-img.c190-bg {
            width: 100%;
            height: 100%;
            background-image: url(https://cdn.shopifycdn.net/s/files/1/1487/4888/files/C188_31cd6996-cd78-4b2a-abb0-e4f300588a72.png?v=1683617211);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
        }
    }